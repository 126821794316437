<script setup lang="ts">
// import { heroImageUrl } from '@/utils/hero'
import site from '~~/site'

const heroImageUrl = ''

// const heroVideoUrl = '/images/hero/banner3.mp4'
const heroVideoUrl = '/images/hero/fire.mp4'

const {
  bookingPath
} = site

</script>
<template>
  <div class="relative bg-gradient-to-r bg-neutral-600 h-screen text-white overflow-hidden bg-center bg-cover bg-no-repeat" >
  <div class="absolute inset-0">
    <!-- <img :src="heroImageUrl" alt="Background Image" class="object-cover object-center w-full h-full video" > -->
    <video class="object-cover object-center w-full h-full"  autoplay playsinline muted loop :poster="heroImageUrl" :src="heroVideoUrl"/>

    <!-- 蒙版 -->
    <!-- <div class="absolute inset-0 bg-black opacity-50"/> -->
  </div>
  
  <div class="relative z-10 flex flex-col justify-center items-center h-full text-center mx-4">
    <h2 class="text-2xl mb-8 font-bold">{{ site.name }}</h2>
    <p class="text-4xl text-center md:text-6xl font-bold leading-tight mb-4">{{ site.slogo }}</p>
    <p class="text-xl md:text-3xl mb-8">Book Your Backyard Hibachi Party Today!</p>
    <!-- <p class="text-lg md:text-xl  mb-8">@ Florida</p> -->

    <BaseButton
                size="xl" label="Book Now" 
                :to="bookingPath">
                <!-- <span class="pl-2">BooK Now</span> -->
                <div class="inline-block mx-8 text-lg">Book Now</div>
              </BaseButton>
  </div>
</div>
</template>
<style >
/* video::-webkit-media-controls-enclosure{
    display: none !important;
} */

.vsc-controller {
  display: none !important;
}
 /* .video {
        background-color:gray;
        background:url("http://cdn.moji.com/websrc/video/video621.mp4") no-repeat center 0px;
    } */
 /* video#bgvid {
        position: fixed; right: 0; bottom: 0;
        min-width: 100%; min-height: 100%;
        width: auto; height: auto; z-index: -100;
        background-size: cover;
    }	 */
</style>
