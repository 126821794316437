<script setup lang="ts">
import { useKeenSlider } from 'keen-slider/vue'
import { reviews as callToActions } from '~~/db.json'
const count = ref(0)
const active = ref(0)
const reviewsTmp = ref([])

reviewsTmp.value = callToActions.slice(0, 9)

const [container, slider] = useKeenSlider({
  loop: true,
  defaultAnimation: {
    duration: 750,
  },
  slides: {
    origin: 'center',
    perView: 1,
    spacing: 16,
  },
  breakpoints: {
    '(min-width: 640px)': {
      slides: {
        origin: 'center',
        perView: 1.5,
        spacing: 16,
      },
    },
    '(min-width: 768px)': {
      slides: {
        origin: 'center',
        perView: 1.75,
        spacing: 16,
      },
    },
    '(min-width: 1024px)': {
      slides: {
        origin: 'center',
        perView: 3,
        spacing: 16,
      },
    }
  },
  created(slider) {
    // slider.slides[slider.track.details.rel].classList.remove('opacity-40')
    active.value = slider.track.details.rel + 1
    count.value = slider.slides.length
  },
  slideChanged(slider) {
    // slider.slides.forEach((slide) => slide.classList.add('opacity-40'))
    // slider.slides[slider.track.details.rel].classList.remove('opacity-40')
    active.value = slider.track.details.rel + 1
    // reviewsTmp.value = callToActions

  },
}, [
  // add plugins here
])

</script>

<template>
    <SectionHeader title="Customer Reviews" desc="We have some fans." />

  <section class="container mx-auto px-4">
    <div class="mx-auto max-w-screen-xl">
      <div ref="container" class="keen-slider">
        <div v-for="callToAction in reviewsTmp" :key="callToAction.id"
          class="keen-slider__slide transition-opacity duration-500">
          <UserReview :review="callToAction" />
        </div>
      </div>

      <div class="mt-6 flex items-center justify-center gap-4">
        <button aria-label="Previous slide"
          class="text-gray-600 transition-colors hover:text-gray-900" @click="slider.prev()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="size-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>

        <p class="w-16 text-center text-sm text-gray-700">
          <span>{{ active }}</span>
          /
          <span>{{ count }}</span>
        </p>
        <button aria-label="Next slide"
          class="text-gray-600 transition-colors hover:text-gray-900" @click="slider.next()">
          <svg class="size-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5l7 7-7 7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          </svg>
        </button>
      </div>
    </div>
  </section>
</template>


<style>
@import url('keen-slider/keen-slider.css');
</style>
